import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

/* Import Local Components */
import EditHeader from "./components/editHeader"

/* Import Local Style(s) */
import "./post.scss"

const Post = ({ location, data: { sanityEditPost: editPage, sanityWebsiteConfiguration: config } }) => {
  
  // console.log(location)

  const { title, byline, publicationDate, _rawBylineBio, color, matrix } = editPage
  const bg = (color && color.color) ? color.color.hex : 'var(--color-lightest-gray)'
  

  return (
    <Page title={title} location={location} className="template">
      <Helmet>
        <style>{`body { background-color: ${bg}; }`}</style>
      </Helmet>
      <EditHeader 
        title={title} 
        location={location}
        date={publicationDate}
        byline={byline}
      />
      <PageArticle title={title} className="post edit-post">
        <PageArticleSection className="post font-size--small">
          {matrix.map((m, i) => {

            if(m.__typename == 'SanityEditText'){
              return (<div className="edit_text" key={m._key}><BlockContent blocks={m._rawContent} /></div>)
            }else if(m.__typename == 'SanityEditImage'){
              return (<figure 
                className={'edit_image edit_size--' + m.imageSize} 
                key={'sanity-' + i}
              >
                {m.primaryImage && <Image image={m.primaryImage.asset.gatsbyImageData} />}
                {m.primaryImage && m.primaryImage.caption && <figcaption className="mt--1_2 text--white">{m.primaryImage.caption}</figcaption>}
              </figure>)
            }else if(m.__typename == "SanityEditTwoImages"){
              return (<div className="edit_two-up" key={'sanity-' + i}>
                {m.primaryImage && <figure>
                  {m.primaryImage.asset && <Image image={m.primaryImage.asset.gatsbyImageData} />}
                  {m.primaryImage.caption && <figcaption className="mt--1_2 text--white">{m.primaryImage.caption}</figcaption>}
                </figure>}
                {m.secondImage && <figure>
                  {m.secondImage.asset && <Image image={m.secondImage.asset.gatsbyImageData} />}
                  {m.secondImage.caption && <figcaption className="mt--1_2 text--white">{m.secondImage.caption}</figcaption>}
                </figure>}
              </div>)
            }

          })}

          {_rawBylineBio && <div className="edit-bio text--white mt--1">
            <BlockContent blocks={_rawBylineBio} />
          </div>}

        </PageArticleSection>
      </PageArticle>
    </Page>
  )
}

export default Post

export const query = graphql`
  query ($id: String!) {
    sanityEditPost(id: { eq: $id }) {
      ...fieldsOnEditPostDocument
    }
    sanityWebsiteConfiguration {
      ...fieldsOnWebsiteConfigurationDocument
    }
  }
`
